import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React from "react";
import Grow from "@mui/material/Grow";
import "./navbar.css";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import * as Muicon from "@material-ui/icons";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PeopleIcon from "@mui/icons-material/People";
import KeyIcon from "@mui/icons-material/Key";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BusinessIcon from "@mui/icons-material/Business";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import Settings from "@mui/icons-material/Settings";
import HouseSidingIcon from "@mui/icons-material/HouseSiding";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DatasetIcon from "@mui/icons-material/Dataset";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

// const Icon = ({ name, ...rest }) => {
//   const IconComponent = Muicon[name];
//   return IconComponent ? <IconComponent {...rest} /> : null;
// };

const IconList = {
  ReceiptIcon,
  PeopleIcon,
  KeyIcon,
  AssignmentIndIcon,
  BusinessIcon,
  HomeWorkIcon,
  WarehouseIcon,
  Settings,
  HouseSidingIcon,
  CurrencyExchangeIcon,
  DatasetIcon,
  AccountBalanceWalletIcon,
};

const Icon = ({ name, ...rest }) => {
  const IconComponent = IconList[name];
  return IconComponent ? <IconComponent {...rest} /> : null;
};
export default function Menus({
  index = 0,
  data,
  marginLeft,
  flag = false,
  primaryLight,
}) {
  var set = [];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: marginLeft,
        // border: '1px solid black',
      }}
    >
      {data
        // ?.filter((item) => item.path !== "/production")
        ?.map((item, index) => {
          if (
            item.children?.some(
              (child) => child.title === item.title && child.single
            )
          ) {
            return (
              <SingleLevel
                item={item}
                key={index + 1}
                primaryLight={primaryLight}
              />
            );
          } else {
            return item.children?.length ? (
              <Dropdown
                o={item}
                key={index + 1}
                set={set}
                index={index}
                primaryLight={primaryLight}
              />
            ) : (
              flag && (
                <SingleLevel
                  item={item}
                  key={index + 1}
                  primaryLight={primaryLight}
                />
              )
            );
          }
          // return item.children ? (
          //   <Dropdown o={item} key={index + 1} set={set} index={index} />
          // ) : (
          //   <SingleLevel item={item} key={index + 1} />
          // )
        })}
    </Box>
  );
}

function SingleLevel({ item, primaryLight }) {
  if (item.path) {
    return (
      <ListItemButton
        className={isNavLinkActive(item) ? "active" : ""}
        component={Link}
        to={item.path}
        sx={{
          width: "90%",
          height: "35px",
          borderTopRightRadius: 100,
          borderBottomRightRadius: 100,
          // color: "rgba(58, 53, 65, 0.87)",
          color: primaryLight,
          // border: '1px solid black',
          "&.active, &.active:hover": {
            background: "linear-gradient(73deg, #C6A7FE, #7456d3)",
          },
          "&.active .MuiTypography-root, &.active .MuiSvgIcon-root": {
            color: `#FFF !important`,
            // color: primaryLight,
          },
        }}
        onClick={() => {
          if (
            item?.path !== "/new-purchase" &&
            localStorage.getItem("purchaseVoucher") !== null
          ) {
            localStorage.removeItem("purchaseVoucher");
          }
          if (
            item?.path !== "/new-sale" &&
            localStorage.getItem("saleVoucher") !== null
          ) {
            localStorage.removeItem("saleVoucher");
          }
          if (
            item?.path !== "/new-referral-guide" &&
            localStorage.getItem("referralGuideVoucher") !== null
          ) {
            localStorage.removeItem("referralGuideVoucher");
          }

          if (
            item?.path !== "/new-merchandise-service" &&
            localStorage.getItem("newMerchandiseService") !== null
          ) {
            localStorage.removeItem("newMerchandiseService");
          }
        }}
      >
        <ListItemIcon
          sx={{
            margin: 0,
            paddingLeft: 0.7,
            minWidth: 33,
            color: primaryLight,
          }}
        >
          {/* <PanoramaFishEyeIcon sx={{ fontSize: "10px" }} /> */}
          <Icon name={item.icon} sx={{ fontSize: "17px" }} />
        </ListItemIcon>
        <ListItemText primary={item?.title} />
      </ListItemButton>
    );
  }
}

function Dropdown({ o, set, index, primaryLight }) {
  const [open, setOpen] = React.useState(false);
  set[index] = setOpen;
  // o.show = true
  return (
    <>
      <ListItemButton
        sx={{
          width: "90%",
          borderTopRightRadius: 100,
          borderBottomRightRadius: 100,
          color: primaryLight,
          // border: '1px solid black',
          height: "35px",
        }}
        onClick={() => {
          set.map((setOpen, i) => {
            if (i === index) {
              setOpen((prev) => !prev);
            } else {
              setOpen(false);
            }
          });
        }}
      >
        <ListItemIcon
          sx={{
            margin: 0,
            paddingLeft: 0.7,
            minWidth: 33,
            color: primaryLight,
          }}
        >
          <Icon name={o.icon} sx={{ fontSize: "17px" }} />
        </ListItemIcon>
        <ListItemText primary={o?.title} sx={{ color: primaryLight }} />

        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Menus
          item={o}
          data={o?.children}
          marginLeft={2}
          flag={true}
          primaryLight={primaryLight}
        />
      </Collapse>
    </>
  );
}
const isNavLinkActive = (item) => {
  return window.location.pathname === item?.path;
};
