import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import { permissions } from "../../../pages/administration/userPermits/roles/permissions";
import React, { useState, useEffect, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import * as service from "../../../services/service";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import UserInfo from "./UserInfo";
import { useUserBranch } from "../../../hooks/users/useUserBranch";
import { useSelector, useDispatch } from "react-redux";
import { setMode } from "../../../state";
import {
  setCompany,
  setPermissions,
  setBranch,
  setBrands,
} from "../../../state";
import { Checkbox, ListItemText, useTheme } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { Navigate } from "react-router-dom";
import "./navbar.css";
import Menu from "./Menus";
import TextField from "@mui/material/TextField";

export function ControlBranch({ sx }) {
  const company = useSelector((state) => state.company);
  const branch = useSelector((state) => state.branch);
  const userCompanies = useSelector((state) => state.userCompanies);

  const dispatch = useDispatch();
  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;

  return (
    <FormControl sx={{ width: 200, ...sx }} size="small">
      <InputLabel id="branchOffice">Sucursal</InputLabel>
      <Select
        labelId="branchOffice"
        label="Sucursal"
        value={branch?.id || 0}
        sx={{
          backgroundColor: neutralLight,
          "& .MuiSelect-select:focus": {
            backgroundColor: neutralLight,
          },
        }}
        onChange={(event) =>
          dispatch(
            setBranch({
              branchId: event.target.value,
            })
          )
        }
      >
        {userCompanies?.map((element) =>
          element.isSelected
            ? element.branches.map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))
            : null
        )}
      </Select>
    </FormControl>
  );
}

export function CheckBoxBranch({ sx }) {
  const userCompanies = useSelector((state) => state.userCompanies) || [];
  const selectedBrands = useSelector((state) => state.selectedBrands);
  const { branches } = userCompanies.find((item) => item.isSelected);

  const dispatch = useDispatch();
  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;

  // useEffect(() => {
  //   console.log("selectedBrands", selectedBrands);
  // }, [selectedBrands]);

  return (
    <FormControl sx={{ width: 200, ...sx }} size="small">
      <InputLabel id="branchOffice">Sucursal</InputLabel>
      <Select
        labelId="branchOffice"
        label="Sucursal"
        value={selectedBrands}
        multiple
        renderValue={(selected) => {
          return branches
            .reduce((acumulator, currentValue) => {
              if (selected.includes(currentValue.id)) {
                acumulator.push(currentValue.name);
              }
              return acumulator;
            }, [])
            .join(", ");
        }}
        sx={{
          backgroundColor: neutralLight,
          "& .MuiSelect-select:focus": {
            backgroundColor: neutralLight,
          },
        }}
        onChange={(event) =>
          dispatch(
            setBrands({
              brands: event.target.value,
            })
          )
        }
      >
        {branches?.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            <Checkbox checked={selectedBrands.indexOf(item.id) > -1} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const drawerWidth = 240;

function ResponsiveDrawer({ children }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);

  const { getUserCompanies, getBranchUserPermissions } = useUserBranch(token);
  const userCompanies = useSelector((state) => state.userCompanies);
  // const { branches } = userCompanies.find((item) => item.isSelected);

  const company = useSelector((state) => state.company);
  const branch = useSelector((state) => state.branch);
  const [sidebarMenus, setSidebarMenus] = useState([]);
  const dispatch = useDispatch();

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const primaryLight = theme.palette.primary.text;
  const alt = theme.palette.background.alt;

  useEffect(() => {
    var sss = window.location.pathname.split("/");
    // console.log('ss', sss, window.location.pathname)
    if (sss[1] === "pdf-viewer") {
    } else {
      getUserCompanies().then((error) => {
        if (error) {
          console.log("error", error);
          navigate("/login");
        }
      });
    }

    // console.log('window.location.pathname', window.location.pathname)
    //window.location.pathname
  }, []);

  useEffect(() => {
    if (user) {
      setSidebarMenus(user?.permissions);
      // console.log("setSidebarMenus", user?.permissions);
    }
  }, [user, branch.id]);

  useEffect(() => {
    if (branch?.id >= 0) {
      getBranchUserPermissions(branch?.id, token);
    }
    // console.log("getBranchUserPermissions");
  }, [branch.id]);

  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    var flag = false;
    permissions.map((sidebarObj, index) => {
      if (window.location.pathname === sidebarObj.path) {
        flag = true;
      }
      sidebarObj.children.map((obj) => {
        // console.log(
        //   'window.location.pathname === obj.path',
        //   window.location.pathname,
        //   obj.path
        // )

        if (window.location.pathname === "/new-referral-guide") {
          flag = true;
        }
        if (window.location.pathname === obj.path) {
          flag = true;
        }
        obj.children?.map((obj) => {
          if (window.location.pathname === obj.path) {
            flag = true;
          }
        });
      });
    });

    // console.log("window.location.pathname", window.location.pathname);
    if (flag || window.location.pathname === "/") {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  }, [window.location.pathname]);

  // console.log('on loading sidebar by segunda vez')

  function CheckPath({ value }) {
    const pathname = window.location.pathname.substring(
      window.location.pathname.indexOf("/") + 1
    );
    var flag = false;
    // var flag = service.checkPathPermission(user?.permissions, [], pathname)
    flag = true;
    // console.log(flag)
    if (flag) {
      return value;
    } else {
      return (
        <Alert severity="error">
          <AlertTitle>Permiso denegado</AlertTitle>
          Póngase en contacto con su administrador de daphtech si cree que se
          trata de un error.
        </Alert>
      );
    }
  }

  function RequireAuth({ children }) {
    // const isAuthUser = Boolean(useSelector((state) => state.token))

    // const obj = children?.props?.children?.find(
    //   (value) => window.location.pathname === value.props.path
    // )
    // if (!obj) return children
    // if (!obj.props.requireAuth && !obj.props.requireSidebar) return children
    // if (!obj.props.requireAuth && obj.props.requireSidebar) return Component

    // console.log(window.location.pathname)
    // if (isAuthUser) {
    //   if (obj.props.requireSidebar) return Component
    //   return children
    // } else {
    //   return <Navigate to="/login" />
    // }
    return children;
  }

  const drawer = (
    <Box
      className="test"
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: neutralLight,
        // backgroundColor: alt,
        // backgroundColor: background,
        // border: '5px solid rgb(102, 97, 97)',
      }}
    >
      <div
        className="company-and-user-info"
        style={{
          display: "flex",
          // backgroundColor: alt,
        }}
      >
        <img
          src={company.image}
          alt="jobs"
          style={{
            marginLeft: "10%",
            marginTop: "10px",
            width: "70%",
            height: "100px",
            borderRadius: "9px",
            overflow: "hidden",
            //transform: "scale(1.3)",
            //border: "1px solid #0d0d0f",
          }}
        />
      </div>
      <FormControl sx={{ ml: 2, mr: 2, mt: 2.5, width: 200 }} size="small">
        <TextField
          label="Empresa"
          size="small"
          value={company?.name || 0}
          InputProps={{
            readOnly: true,
          }}
        />
        {/* <InputLabel id="selectCompany">Empresa</InputLabel>
        <Select
          labelId="selectCompany"
          label="Empresa"
          value={company?.id || 0}
          onChange={(event)=>dispatch(
      setCompany({
        companyId: event.target.value,
      })
    )}
        >
          {userCompanies?.map((element) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          ))}
        </Select> */}
      </FormControl>
      {/* <FormControl sx={{ m: 2, width: 200 }} size="small">
        <InputLabel id="branchOffice">Sucursal</InputLabel>
        <Select
          labelId="branchOffice"
          label="Sucursal"
          value={branch?.id || 0}
          sx={{
            backgroundColor: neutralLight,
            "& .MuiSelect-select:focus": {
              backgroundColor: neutralLight,
            },
          }}
          onChange={(event) =>
            dispatch(
              setBranch({
                branchId: event.target.value,
              })
            )
          }
        >
          {userCompanies?.map((element) =>
            element.isSelected
              ? element.branches.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                ))
              : null
          )}
        </Select>
      </FormControl> */}
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1.5,
          // backgroundColor: background,
        }}
      >
        <Menu data={sidebarMenus} primaryLight={primaryLight} />
      </List>
    </Box>
  );

  const wrapper = window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      {
        // false &&
        showSidebar && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // backgroundColor: alt,
            }}
          >
            <AppBar
              position="fixed"
              sx={{
                width: { sm: `calc(100% - ${10}px)` },
                ml: { sm: `${10}px` },
                backgroundColor: neutralLight,
                //border: "1px solid black",
              }}
            >
              <Toolbar
              // sx={{ backgroundColor: alt }}
              // backgroundColor="primary.light"
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon sx={{ color: "black" }} />
                </IconButton>

                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs></Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        navigate(-1);
                      }}
                      size="large"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "inline-block",
                        },
                        // border: '1px solid black',
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <ReplyAllIcon
                        size="large"
                        sx={{
                          minWidth: 100,
                          minHeight: 40,
                          // border: '1px solid black',
                        }}
                      />
                    </IconButton>
                    <IconButton onClick={() => dispatch(setMode())}>
                      {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon sx={{ fontSize: "25px" }} />
                      ) : (
                        <LightModeOutlinedIcon
                          sx={{ color: dark, fontSize: "25px" }}
                        />
                      )}
                    </IconButton>

                    <UserInfo user={user} />
                    <IconButton></IconButton>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Box
              component="nav"
              sx={{
                width: { sm: drawerWidth },
                flexShrink: { sm: 0 },
                // backgroundColor: "white !Important",
                //border: "5px solid black !Important",
              }}
              aria-label="mailbox folders"
            >
              <Drawer
                container={wrapper}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>
              <Drawer
                variant="permanent"
                sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 2,
                width: {
                  sm: `calc(100% - ${drawerWidth}px)`,
                  xs: `calc(100% - ${drawerWidth}px)`,
                  backgroundColor: neutralLight,
                },
              }}
            >
              <Toolbar />
            </Box>
          </Box>
        )
      }
    </>
  );
}

export default ResponsiveDrawer;
