import { lazy, Suspense } from "react";

import { Route, Routes as Router } from "react-router-dom";
import "./App.scss";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const NewSale = lazy(() => import("./pages/pointOfSale/newSale/NewSale.jsx"));
const Users = lazy(() =>
  import("./pages/administration/userPermits/users/UsersTable.js.jsx")
);
const Login = lazy(() => import("./pages/login/SignIn.js"));
const Customers = lazy(() =>
  import("./pages/administration/auxiliares/customers/CustomersTable.js.jsx")
);
const Warehouses = lazy(() =>
  import(
    "./pages/administration/companiesBranchs/warehouses/WarehousesTable.js"
  )
);
const Products = lazy(() =>
  import("./pages/administration/articles/products/ProductsTable.js")
);
const UnitsMeasurement = lazy(() =>
  import(
    "./pages/administration/articles/unitsMeasurement/UnitsMeasurementTable.js"
  )
);
const PricesProducts = lazy(() =>
  import("./pages/administration/articles/pricesProducts/PricesProducts.js")
);
const Classifications = lazy(() =>
  import(
    "./pages/administration/articles/classifications/ClassificationsTable.js"
  )
);
const Categories = lazy(() =>
  import("./pages/administration/articles/categories/CategoriesTable.js")
);
const Subcategories = lazy(() =>
  import("./pages/administration/articles/subcategories/SubcategoriesTable.js")
);
const ProductPackages = lazy(() =>
  import(
    "./pages/administration/articles/productPackages/ProductPackagesTable.js"
  )
);
const Conditions = lazy(() =>
  import("./pages/administration/document/conditions/ConditionsTable.js")
);
const Documents = lazy(() =>
  import("./pages/administration/document/documents/DocumentsTable.js")
);
const Sales = lazy(() => import("./pages/pointOfSale/sales/SalesTable.jsx"));
const Companies = lazy(() =>
  import(
    "./pages/administration/companiesBranchs/companies/CompaniesTable.js.jsx"
  )
);
const Branches = lazy(() =>
  import("./pages/administration/companiesBranchs/branches/BranchesTable.js")
);
const Roles = lazy(() =>
  import("./pages/administration/userPermits/roles/RolesTable.js")
);
const DocumentsPerUser = lazy(() =>
  import(
    "./pages/administration/userPermits/documentsPerUser/DocumentsPerUser.jsx"
  )
);
const Kardex = lazy(() => import("./pages/logistics/kardex/KardexTable.js"));
const NewPurchase = lazy(() =>
  import("./pages/logistics/newPurchase/NewPurchase.jsx")
);
const PurchasesTable = lazy(() =>
  import("./pages/logistics/purchases/PurchasesTable.jsx")
);
const Inventory = lazy(() =>
  import("./pages/logistics/inventory/InventoryTable.js")
);
const PdfViewer = lazy(() => import("./pages/pdf/PdfViewer.jsx"));
const Caja = lazy(() => import("./pages/finance/caja/caja.jsx"));
const ReporteDetallado = lazy(() =>
  import("./pages/finance/caja/reporteDetallado/ReporteDetallado.jsx")
);
// const SupplierAccounts = lazy(() =>
//   import("./pages/finance/cuentas-proveedores/CuentasClientesTable.js")
// );
// const CustomerAccounts = lazy(() =>
//   import("./pages/finance/cuentas-clientes/CuentasClientesTable.js")
// );
const Profit = lazy(() => import("./pages/utilidad/UtilidadTable.jsx"));
const ReferenceGuides = lazy(() =>
  import("./pages/referenceGuides/referenceGuides/ReferenceGuidesTable.jsx")
);
const NewReferralGuide = lazy(() =>
  import("./pages/referenceGuides/newReferralGuide/NewReferralGuide.jsx")
);
const Transporters = lazy(() =>
  import("./pages/administration/auxiliares/transporters/TransportersTable.jsx")
);
const Drivers = lazy(() =>
  import("./pages/administration/auxiliares/drivers/DriversTable.jsx")
);
const Vehicles = lazy(() =>
  import("./pages/administration/vehicles/VehiclesTable.jsx")
);
const SalesBookTable = lazy(() =>
  import("./pages/accounting/SalesBookTable.js")
);
const NewTravel = lazy(() =>
  import("./pages/merchandiseTransports/newTravel/NewTravel.jsx")
);
const Travels = lazy(() =>
  import("./pages/merchandiseTransports/travels/TravelsTable.jsx")
);
const VehicleMaintenance = lazy(() =>
  import(
    "./pages/merchandiseTransports/vehicleMaintenance/VehicleMaintenanceTable.jsx"
  )
);
const AdditionalCosts = lazy(() =>
  import(
    "./pages/merchandiseTransports/additionalCosts/AdditionalCostsTable.jsx"
  )
);
const DriverExpenses = lazy(() =>
  import("./pages/merchandiseTransports/driverExpenses/DriverExpensesTable.jsx")
);
const FuelCosts = lazy(() =>
  import("./pages/merchandiseTransports/fuelCosts/FuelCostsTable.jsx")
);
const TransporteUtilidad = lazy(() =>
  import("./pages/merchandiseTransports/utilidad/UtilidadTable.jsx")
);
const Employees = lazy(() =>
  import("./pages/humanResources/employees/EmployeesTable.jsx")
);
const PayrollWorker = lazy(() =>
  import("./pages/humanResources/payrollWorker/PayrollWorkerTable.jsx")
);
const WorkerReceiptFee = lazy(() =>
  import("./pages/humanResources/WorkerReceiptFee/WorkerReceiptFeeTable.jsx")
);
const InternalControlWorker = lazy(() =>
  import(
    "./pages/humanResources/InternalControlWorker/InternalControlWorkerTable.jsx"
  )
);
const WorkerAccounts = lazy(() =>
  import("./pages/Caja&Bancos/workersAccounts/WorkerAccountsTable.jsx")
);

const Templates = lazy(() =>
  import("./pages/documentManagement/templates/Templates.jsx")
);
const LettersDocuments = lazy(() =>
  import("./pages/documentManagement/documents/Documents.jsx")
);
const Accounts = lazy(() =>
  import("./pages/Caja&Bancos/accounts/Accounts.jsx")
);
const BankingTransactions = lazy(() =>
  import("./pages/Caja&Bancos/bankingTransaction/BankingTransactions.jsx")
);
const MovementCategory = lazy(() =>
  import("./pages/Caja&Bancos/movementCategory/MovementCategory.jsx")
);
const MovementSubcategory = lazy(() =>
  import("./pages/Caja&Bancos/movementSubcategory/MovementSubcategory.jsx")
);

const CustomerAccountTable = lazy(() =>
  import("./pages/Caja&Bancos/customerAccounts/CustomerAccountTable.js")
);
const SupplierAccountTable = lazy(() =>
  import("./pages/Caja&Bancos/supplierAccounts/SupplierAccountTable.js")
);

const Balance = lazy(() => import("./pages/Caja&Bancos/balance/Balance.jsx"));

const Epps = lazy(() => import("./pages/epps/EppsTable.jsx"));

function MissingRoute() {
  return <span>{"Page not found"}</span>;
}

function Auth({ value }) {
  const pathname = window.location.pathname.substring(
    window.location.pathname.indexOf("/") + 1
  );
  // console.log('pathname', pathname)
  var flag = false;
  // var flag = service.checkPathPermission(user?.permissions, [], pathname)
  flag = true;
  // console.log(flag)
  if (flag) {
    return value;
  } else {
    return (
      <Alert severity="error">
        <AlertTitle>Permiso denegado</AlertTitle>
        Póngase en contacto con su administrador de daphtech si cree que se
        trata de un error.
      </Alert>
    );
  }
}

const sx = {
  paddingRight: 2,
  paddingLeft: {
    xs: 1,
    sm: 100 % -68,
    // md: 100 % -69,
    // lg: 100 % -67,
  },
};
export default function Routes({ backgroundColor }) {
  sx.backgroundColor = backgroundColor;
  return (
    <Router>
      <Route
        path="/"
        element={
          <Auth
            value={
              <Suspense fallback={<div>Loading...</div>}>
                <NewSale sx={sx} />
              </Suspense>
            }
            // <NewSale sx={sx}/>
          />
        }
      />
      <Route
        path="/login"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Login />
          </Suspense>
        }
      />

      <Route path="*" element={<MissingRoute />} />
      <Route
        path="/users"
        element={
          <Auth
            value={
              <Suspense fallback={<div>Loading...</div>}>
                <Users sx={sx} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/new-sale"
        element={
          <Auth
            value={
              <Suspense fallback={<div>Loading...</div>}>
                <NewSale sx={sx} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/sales"
        element={
          <Auth
            value={
              <Suspense fallback={<div>Loading...</div>}>
                <Sales sx={sx} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/clients"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Customers sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/companies"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Companies sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/branches"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Branches sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/warehouses"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Warehouses sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/products"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Products sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/units-measurement"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <UnitsMeasurement sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/classifications"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Classifications sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/categories"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Categories sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/subcategories"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Subcategories sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/product-packages"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <ProductPackages sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/prices-products"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <PricesProducts sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/conditions"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Conditions sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/documents"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Documents sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/roles"
        element={
          <Auth
            value={
              <Suspense fallback={<div>Loading...</div>}>
                <Roles sx={sx} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/user-documents"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <DocumentsPerUser sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/kardex"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Kardex sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/inventory"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Inventory sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/new-purchase"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <NewPurchase sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/purchases"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <PurchasesTable sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/caja"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Caja sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/reporte-caja"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <ReporteDetallado sx={sx} />
          </Suspense>
        }
      />

      <Route
        path="/pdf-viewer/:documentType/:id"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <PdfViewer />
          </Suspense>
        }
      />
      <Route
        path="/profit"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Profit sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/referral-guides"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <ReferenceGuides sx={sx} />
          </Suspense>
        }
      />

      <Route
        path="/new-referral-guide"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <NewReferralGuide sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/transporters"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Transporters sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/drivers"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Drivers sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/vehicles"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Vehicles sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/sales-book"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <SalesBookTable sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/new-merchandise-service"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <NewTravel sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/merchandise-services"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Travels sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/vehicle-maintenance"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <VehicleMaintenance sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/additional-costs"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <AdditionalCosts sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/driver-expenses"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <DriverExpenses sx={sx} />
          </Suspense>
        }
      />

      <Route
        path="/transporte-utilidad"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <TransporteUtilidad sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/fuel-costs"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <FuelCosts sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/employees"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Employees sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/payroll-worker"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <PayrollWorker sx={sx} />
          </Suspense>
        }
      />

      <Route
        path="/worker-receipt-Fee"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <WorkerReceiptFee sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/internal-control-worker"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <InternalControlWorker sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/employees-accounts"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <WorkerAccounts sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/document-templates"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Templates sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/letters-documents"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <LettersDocuments sx={sx} />
          </Suspense>
        }
      />

      <Route
        path="/accounts"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Accounts sx={sx} />
          </Suspense>
        }
      />

      <Route
        path="/banking-transactions"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <BankingTransactions sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/movement-category"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <MovementCategory sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/movement-subcategory"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <MovementSubcategory sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/customer-account-cash-bank"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <CustomerAccountTable sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/supplier-account-cash-bank"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <SupplierAccountTable sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/account-balance"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Balance sx={sx} />
          </Suspense>
        }
      />
      <Route
        path="/personal-safety-protective-equipment"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Epps sx={sx} />
          </Suspense>
        }
      />
    </Router>
  );
}
