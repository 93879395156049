import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  // user: { name: '', email: '', permissions: [] },
  user: {},
  token: null,
  userCompanies: [],
  company: {},
  branch: {},
  selectedBrands: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      let acronym = action.payload.user.name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
      state.user = action.payload.user;
      state.user.acronym = acronym.toUpperCase();
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.user = {};
      state.token = null;
      state.userCompanies = [];
      state.company = {};
      state.branch = {};
    },
    setUserCompanies: (state, action) => {
      if (action.payload.userCompanies.length) {
        state.userCompanies = action.payload.userCompanies;
        state.userCompanies[0].isSelected = true;
        state.userCompanies[0].branches[0].isSelected = true;
        var company = state.userCompanies.find((e) => e.isSelected);
        state.company = company;
        var branch = company.branches.find((e) => e.isSelected);
        state.branch = branch;
      } else {
        state.userCompanies = [];
        state.company = {};
        state.branch = {};
      }
    },
    setCompany: (state, action) => {
      state.userCompanies?.map((el) => {
        el.isSelected = false;
        if (el.id === action.payload.companyId) {
          el.isSelected = true;
          el.branches[0].isSelected = true;
        }
      });
      var company = state.userCompanies.find((e) => e.isSelected);
      state.company = company;
      var branch = state.company.branches.find((e) => e.isSelected);
      state.branch = branch;
    },
    setBranch: (state, action) => {
      state.userCompanies.map((el) => {
        if (el.isSelected) {
          el.branches.map((item) => {
            item.isSelected = false;
            if (item.id === action.payload.branchId) {
              state.branch = item;
              item.isSelected = true;
            }
          });
        }
      });
    },
    setPermissions: (state, action) => {
      if (state.user) {
        state.user.permissions = action.payload.permissions;
      }
    },
    setBrands: (state, action) => {
      state.selectedBrands = action.payload.brands;
    },
  },
});

export const {
  setMode,
  setLogin,
  setLogout,
  setFriends,
  setPosts,
  setPost,
  setUserCompanies,
  setCompany,
  setBranch,
  setBrands,
  setPermissions,
} = authSlice.actions;
export default authSlice.reducer;
