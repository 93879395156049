import * as XLSX from "xlsx";

export const environment = "prod";

const urlProdFac = "http://93.188.164.120:7002";
const urlProdSunat = "http://93.188.164.120:7001";

export const urlServer =
  environment === "prod" ? urlProdFac : "http://127.0.0.1:5002";

export const urlServerSunat =
  environment === "prod" ? urlProdSunat : "http://127.0.0.1:8000";

export async function sendSunat(copyInputs, server) {
  const response = await fetch(server, {
    method: "POST",
    headers: {
      Accept: "application.json",
      //'Content-Type': 'application/json'
      //'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    body: JSON.stringify(copyInputs), // body data type must match "Content-Type" header
  });

  var data = await response.json();
  return data;
}

export function zeroPad(num, places) {
  // const zeroPad = (num, places) => String(num).padStart(places, "0");
  return String(num).padStart(places, "0");
}

// export function isNumber(str) {
//   return /^\d+$/.test(str);
// }

export function isNumber(str) {
  str = parseFloat(str);
  if (typeof str == "number" && !isNaN(str)) {
    if (Number.isInteger(str)) {
      // int
      return true;
    } else {
      // float
      return true;
    }
  } else {
    return false;
  }
}

export function dateFormat(value) {
  // console.log('typeof value',typeof value);
  if (
    typeof value === "object" ||
    typeof value === "number" ||
    typeof value === "string"
  ) {
    var date = new Date(value);
    var day =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();
    var month =
      (date.getMonth() + 1).toString().length === 1
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var year = date.getFullYear();
    return day + "-" + month + "-" + year;
  } else {
    return value;
  }
}

export function dateFormatForSunat(value) {
  if (
    typeof value === "object" ||
    typeof value === "number" ||
    typeof value === "string"
  ) {
    var date = new Date(value);
    var day =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();
    var month =
      (date.getMonth() + 1).toString().length === 1
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var year = date.getFullYear();
    return year + "-" + month + "-" + day;
  } else {
    return value;
  }
}

export function dateFormatss(value) {
  if (value) {
    value = value.split("-");
    value = value[2] + "-" + value[1] + "-" + value[0];
    return value;
  }
}

export const exportTemplateExcel = (template, title) => {
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(template);
  XLSX.utils.book_append_sheet(wb, ws, title);
  XLSX.writeFile(wb, title + ".xlsx");
};

export function getDateTime() {
  var today = new Date();
  var date =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;
  // console.log(date)
  return date;
}

export const checkPathPermission2 = (userPermits, permit) => {
  var flag = false;
  userPermits?.map((sidebarObj, index) => {
    if (permit === sidebarObj.id) {
      flag = true;
    }
    sidebarObj.children.map((obj) => {
      if (permit === obj.id) {
        flag = true;
      }
      obj.children?.map((obj) => {
        if (permit === obj.id) {
          flag = true;
        }
      });
    });
  });
  return flag;
};
export const checkPathPermission = (
  userPermits,
  exceptions,
  pathname,
  sidebarObj
) => {
  var flag = false;

  console.log("userPermits", userPermits);
  userPermits?.map((value, index) => {
    if (exceptions.indexOf(window.location.pathname) !== -1) {
      flag = true;
    }

    // value.checks.some((o) => o.selectedIds.map((ob) => ob).includes(pathname) )

    // const pathname2 = value?.path?.substring(value?.path?.indexOf('/') + 1)
    // console.log(pathname, index)
    // if (pathname === pathname2) {
    //   flag = true
    // }

    value.menus?.map((object) => {
      if (Array.isArray(object.selectedIds)) {
        // console.log(object, sidebarObj)

        // sidebarObj = sidebarObj?.filter((item) => item === pathname)
        // if (object.selectedIds?.map((o) => o).includes(pathname)) {
        //   flag = true
        // }
        if (object.selectedIds?.includes(pathname)) {
          flag = true;
        }
      } else {
        // console.log(object.title, pathname)
        // console.log(
        //   'object.id , pathname',
        //   object.id,
        //   pathname,
        //   object.id === pathname
        // )
        if (object.id === pathname) {
          flag = true;
        }
      }
    });
  });

  return flag;

  //   var flag = false

  //   permissions?.map((value, index) => {
  //     if (exceptions.indexOf(window.location.pathname) !== -1) {
  //       flag = true
  //     }

  //     // value.checks.some((o) => o.selectedIds.map((ob) => ob).includes(pathname) )

  //     value.checks.map((object) => {
  //       if (Array.isArray(object.selectedIds)) {
  //         if (object.selectedIds?.map((o) => o).includes(pathname)) {
  //           flag = true
  //         }
  //       } else {
  //         // console.log(object.title === pathname)
  //         if (object.id === pathname) {
  //           flag = true
  //         }
  //       }
  //     })
  //   })

  //   return flag
};

export const inWords = (num) => {
  var a = [
    "",
    "uno",
    "dos",
    "tres",
    "cuatro",
    "cinco",
    "seis",
    "siete",
    "ocho",
    "nueve",
    "diez",
    "once ",
    "doce ",
    "trece ",
    "catorce ",
    "quince ",
    "dieciséis ",
    "de diecisiete ",
    "Dieciocho ",
    "diecinueve ",
  ];
  var b = [
    "",
    "",
    "veinte",
    "treinta",
    "cuarenta",
    "cincuenta",
    "sesenta",
    "setenta",
    "ochenta",
    "noventa",
  ];

  if ((num = num.toString()).length > 9) return "overflow";
  var n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return "";
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + " mil "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "cientos "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? " " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        " "
      : "";
  return str;
};
