import * as service from "./service";

const environment = process.env.NODE_ENV;

export const apis = {
  BASE_LOCAL_URL: environment === "development" ? "http://localhost:3000" : "",
  SERVER: service.urlServer,
  LOGIN: "/api/v1/login",
  LOGOUT: "/api/v1/logout",
  COMPANIES: "/api/v1/companies",
  BRANCHES: "/api/v1/branches",
  WAREHOUSES: "/api/v1/warehouses",
  PERMISSIONS: "/api/v1/users/permissions",
  USERS: "/api/v1/users",
  ROLES: "/api/v1/roles",
  PRODUCTS: "/api/v1/products",
  UNITS_MEASUREMENT: "/api/v1/units-measurement",
  CLASSIFICATIONS: "/api/v1/classifications",
  CATEGORIES: "/api/v1/categories",
  SUBCATEGORIES: "/api/v1/subcategories",
  PRODUCT_PACKAGES: "/api/v1/product-packages",
  CONDITIONS: "/api/v1/conditions",
  DOCUMENTS: "/api/v1/documents",
  DOCUMENTS_USER: "/api/v1/documents-user",
  PLANS_INTERNET: "/api/v1/planes-internet",
  CUSTOMERS: "/api/v1/customers",
  SALES: "/api/v1/sales",
  KARDEX: "/api/v1/kardex",
  PURCHASES: "/api/v1/purchases",
  REFERRAL_GUIDES: "/api/v1/referral-guides",
  TRANSPORTERS: "/api/v1/transporters",
  DRIVERS: "/api/v1/drivers",
  SERVICE_TRANSPORT: "/api/v1/service-transport",
  VEHICLES: "/api/v1/vehicles",
  VEHICLES: "/api/v1/vehicles",

  EMPLOYEES: "/api/v1/employees",
  WORK_PERIODS: "/api/v1/work-periods",
  WORKER_RECEIPT_FEES: "/api/v1/worker-receipt-fees",
  INTERNAL_CONTROL_WORKER: "/api/v1/internal-control-worker",

  WORKER_ACCOUNTS: "/api/v1/worker-accounts",

  CAJA: "/api/v1/caja",
  DOCUMENT_TEMPLATE: "/api/v1/document-template",
  LETTER_DOCUMENT: "/api/v1/letter-document",
  VIEW_PDF: "/api/v1/view-pdf",
  CASH_AND_BANK: "/api/v1/cash-and-bank",
  BANKING_TRANSACTION: "/api/v1/banking-transaction",
  MOVEMENT_CATEGORY: "/api/v1/movement-category",
  MOVEMENT_SUBCATEGORY: "/api/v1/movement-subcategory",
  EPPS: "/api/v1/epps",

  // REGISTER_USER: "/api/v1/public/register",
  // GET_ADMIN_DETAILS: "/api/v1/admin/details",
};
