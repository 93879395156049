import "./App.scss";
import React from "react";
import {
  BrowserRouter as Router,
  // Routes,
  Route,
  Navigate,
  // Router,
  Switch,
  Outlet,
} from "react-router-dom";

import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import Sidebar from "./components/account/sidebar/Sidebar";
import Routes from "./Routes";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const neutralLight = theme.palette.neutral.light;

  return (
    <>
      <Router>
        <ThemeProvider theme={theme}>
          <Sidebar />
          <div
            style={{
              height: "90vh",
              backgroundColor: neutralLight,
            }}
          >
            <Routes backgroundColor={neutralLight} />
          </div>
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
