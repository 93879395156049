import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserCompanies, setPermissions } from "../../state";
import { apis } from "../../services/Apis";
import { checkPathPermission } from "../../services/service";

export const useUserBranch = (Auth) => {
  // const [userCompanies, setUserCompanies] = useState([])
  const dispatch = useDispatch();

  const checkPermissionsSidebar = (
    setSidebarTest,
    permissions,
    service,
    user
  ) => {
    // console.log('per', permissions)
    if (user?.permissions) {
      // permissions = permissions.filter((sidebarObj, index) => {
      //   const pathname = sidebarObj?.path?.substring(
      //     sidebarObj?.path?.indexOf('/') + 1
      //   )
      //   var flag = checkPathPermission(
      //     user?.permissions,
      //     [],
      //     pathname,
      //     sidebarObj
      //   )
      //   // console.log('pathname', pathname, user?.permissions, flag)
      //   return true
      // })
    }
    // console.log('permissions', user?.permissions)
    setSidebarTest(user?.permissions);
  };

  const getBranchUserPermissions = async (branchId, token) => {
    var rows = [];
    try {
      var response = await fetch(
        apis.SERVER + apis.PERMISSIONS + "/" + branchId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      response = await response.json();
      const { error, data } = response;

      // console.log("data", data);
      if (!error && data) {
        rows = data;
      }
    } catch (error) {
      console.log(error);
    }
    if (rows.length) {
      // console.log("rows", rows);
      dispatch(
        setPermissions({
          permissions: rows[0]?.role?.permissions,
        })
      );
    }
  };

  async function getUserCompanies() {
    var rows = [];
    var error = "";
    try {
      var ss = apis.SERVER + apis.USERS + "/user-company/test";
      // var sss = "http://localhost:5002/api/v1/users/user-company/test";
      var response = await fetch(ss, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Auth}`,
        },
      });
      response = await response.json();
      const { error: e, data } = response;
      if (e) {
        error = e;
      } else {
        rows = data;
      }
    } catch (e) {
      console.log(e);
    }
    dispatch(
      setUserCompanies({
        userCompanies: rows,
      })
    );

    return error;
  }
  useEffect(() => {
    // execute()
  }, []);

  return {
    getUserCompanies,
    getBranchUserPermissions,
    checkPermissionsSidebar,
  };
};
