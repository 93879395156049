// import Isettings from "../../assets/images/Dtafalonso-Android-L-Settings-L.ico";
// import IconContabilidad from "../../assets/images/accounting.png";
// import IconVentas from "../../assets/images/sales.png";
// import IconCompras from "../../assets/images/carts.png";
// import IconTranferenciaMercancia from "../../assets/images/warehouse.png";
// import IconFinanzas from "../../assets/images/finance.png";
// import IconReportes from "../../assets/images/report.png";
// import IconEstadistica from "../../assets/images/analysis.png";
// import IconNuevaVenta from "../../assets/images/nueva-venta.png";

export const permissions = [
  {
    title: "Realizar venta",
    path: "/new-sale",
    icon: "ReceiptIcon",
    size: "70%",
    position: "50% 30px",
    children: [
      {
        title: "Realizar venta",
        id: "new-sale",
        path: "/new-sale",
        single: true,
      },
    ],
  },
  {
    title: "Configuración",
    path: "/configuracion",
    icon: "Settings",
    children: [
      {
        title: "Vehiculos",
        id: "vehicles",
        path: "/vehicles",
      },
      {
        id: "user-permits",
        selectedIds: [],
        title: "permisos",
        children: [
          {
            id: "users",
            title: "usuarios",
            path: "/users",
            icon: "PeopleIcon",
          },
          {
            id: "roles",
            title: "roles",
            path: "/roles",
            icon: "KeyIcon",
          },
          {
            id: "user-documents",
            title: "docs usuario",
            path: "/user-documents",
            icon: "AssignmentIndIcon",
          },
        ],
      },
      {
        id: "documento",
        selectedIds: [],
        title: "Documento",
        children: [
          {
            id: "condiciones",
            title: "Condiciones",
            path: "/conditions",
          },
          {
            id: "documentos",
            title: "Documentos",
            path: "/documents",
          },
        ],
      },
      {
        id: "articles",
        selectedIds: [],
        title: "Articulos",
        children: [
          {
            id: "products",
            title: "Productos",
            path: "/products",
          },
          {
            id: "unidad-medida",
            title: "Unidad medida",
            path: "/units-measurement",
          },
          // {
          //   id: "clasificacion",
          //   title: "Clasificacion",
          //   path: "/classifications",
          // },
          // {
          //   id: "categorias",
          //   title: "Categorias",
          //   path: "/categories",
          // },
          {
            id: "subcategorias",
            title: "Categorias",
            path: "/subcategories",
          },
          {
            id: "precio-productos",
            title: "Precio productos",
            path: "/prices-products",
          },
          // {
          //   id: "paquetes-productos",
          //   title: "Paquetes-productos",
          //   path: "/product-packages",
          // },
        ],
      },
      {
        id: "auxiliares",
        selectedIds: [],
        title: "Auxiliares",
        children: [
          {
            id: "clientes",
            title: "Clientes",
            path: "/clients",
          },
          {
            id: "transportistas",
            title: "Transportistas",
            path: "/transporters",
          },
          {
            id: "drivers",
            title: "Choferes",
            path: "/drivers",
          },
        ],
      },
      {
        id: "companies-branches",
        selectedIds: [],
        title: "Empresa",
        children: [
          {
            id: "companies",
            title: "Empresa",
            path: "/companies",
            icon: "BusinessIcon",
          },
          {
            id: "branches",
            title: "Sucursales",
            path: "/branches",
            icon: "HomeWorkIcon",
          },
          {
            id: "warehouses",
            title: "Almacenes",
            path: "/warehouses",
            icon: "WarehouseIcon",
          },
        ],
      },
    ],
  },

  {
    title: "Contabilidad",
    path: "/sales-book",
    icon: "AccountBalanceWalletIcon",
    children: [
      {
        title: "Libro de ventas",
        id: "sales-book",
        path: "/sales-book",
      },
    ],
  },
  {
    title: "Ventas",
    path: "/sales",
    icon: "ReceiptIcon",
    size: "70%",
    position: "50% 30px",
    children: [
      {
        title: "Ventas",
        id: "sales",
        path: "/sales",
        single: true,
      },
      // {
      //   title: "Permitir realizar cobranza manual",
      //   id: "allowManualCollection",
      //   path: "",
      // },
    ],
  },
  {
    title: "Guias Remision",
    path: "/referral-guides",
    icon: "DatasetIcon",
    size: "70%",
    position: "50% 30px",
    children: [
      {
        title: "Guias Remision",
        id: "referral-guides",
        path: "/referral-guides",
        single: true,
      },
    ],
  },
  // {
  //   title: "Operaciones",
  //   path: "/operations",
  //   icon: "DatasetIcon",
  //   children: [
  //     {
  //       id: "referral-guides",
  //       title: "guias-remision",
  //       path: "/referral-guides",
  //     },
  //     {
  //       id: "new-referral-guide",
  //       title: "nueva guia remision",
  //       path: "/new-referral-guide",
  //     },
  //   ],
  // },
  {
    title: "Finanzas",
    path: "/finance",
    icon: "CurrencyExchangeIcon",
    children: [
      {
        id: "caja",
        title: "Caja",
        path: "/caja",
      },
      {
        id: "reporte-caja",
        title: "Reporte de caja",
        path: "/reporte-caja",
      },
      // {
      //   id: "supplier-accounts",
      //   title: "C. Proveedores",
      //   path: "/supplier-accounts",
      // },
      // {
      //   id: "customers-accounts",
      //   title: "C. Clientes",
      //   path: "/customers-accounts",
      // },
      // {
      //   id: "employees-accounts",
      //   title: "C. Trabajadores",
      //   path: "/employees-accounts",
      // },
      {
        id: "editCashRow",
        title: "Editar movimientos de caja",
        path: "",
      },
      {
        id: "addInitialBalance",
        title: "Agregar saldo inicial",
        path: "",
      },
    ],
  },
  {
    title: "Logistica",
    path: "/logistics",
    icon: "HouseSidingIcon",
    children: [
      {
        id: "purchases",
        title: "compras",
        path: "/purchases",
      },
      {
        id: "new-purchase",
        title: "nueva-compra",
        path: "/new-purchase",
      },
      {
        id: "kardex",
        title: "kardex",
        path: "/kardex",
      },
      {
        id: "inventory",
        title: "Inventario",
        path: "/inventory",
      },
    ],
  },

  {
    title: "Transporte",
    path: "/transport",
    icon: "ReceiptIcon",
    size: "70%",
    position: "50% 30px",
    children: [
      {
        id: "travel-expenses",
        selectedIds: [],
        title: "Gastos",
        children: [
          {
            id: "vehicle-maintenance",
            title: "M. Vehículos",
            path: "/vehicle-maintenance",
          },
          {
            id: "additional-costs",
            title: "G. Adicionales",
            path: "/additional-costs",
          },
          {
            id: "driver-expenses",
            title: "G. Conductor",
            path: "/driver-expenses",
          },
          {
            id: "fuel-costs",
            title: "G. Combustible",
            path: "/fuel-costs",
          },
        ],
      },

      {
        id: "merchandise-services",
        title: "Ordenes de carga",
        path: "/merchandise-services",
      },
      {
        id: "new-merchandise-service",
        title: "Nueva O.C.",
        path: "/new-merchandise-service",
      },
      {
        id: "transporte-utilidad",
        title: "Utilidad",
        path: "/transporte-utilidad",
      },
    ],
  },

  {
    title: "Recursos Humanos",
    icon: "ReceiptIcon",
    size: "70%",
    position: "50% 30px",
    children: [
      {
        id: "employees",
        title: "Colaboradores",
        path: "/employees",
      },
      {
        id: "/work-periods",
        selectedIds: [],
        title: "P. Trabajo",
        children: [
          {
            id: "payroll-worker",
            title: "T. en planilla",
            path: "/payroll-worker",
            icon: "PeopleIcon",
          },
          {
            id: "worker-receipt-fee",
            title: "T. por RXH",
            path: "/worker-receipt-fee",
            icon: "PeopleIcon",
          },
          {
            id: "internal-control-worker",
            title: "T. C. Interno",
            path: "/internal-control-worker",
            icon: "PeopleIcon",
          },
        ],
      },
    ],
  },
  {
    title: "Utilidad",
    path: "/profit",
    icon: "ReceiptIcon",
    size: "70%",
    position: "50% 30px",
    children: [
      {
        title: "Utilidad",
        id: "profit",
        path: "/profit",
        single: true,
      },
    ],
  },
  {
    title: "Gestión de documentos",
    icon: "ReceiptIcon",
    children: [
      {
        id: "document-templates",
        title: "Plantillas",
        path: "/document-templates",
      },
      {
        id: "letters-documents",
        title: "Documentos",
        path: "/letters-documents",
      },
    ],
  },
  {
    title: "Caja & Bancos",
    icon: "ReceiptIcon",
    children: [
      {
        id: "/accounts",
        title: "Cuentas",
        path: "/accounts",
      },
      {
        id: "bankingTransactions",
        title: "Movimientos",
        path: "/banking-transactions",
      },
      {
        id: "accountBalance",
        title: "Saldo",
        path: "/account-balance",
      },
      {
        id: "customerAccount",
        title: "Cuenta Clientes",
        path: "/customer-account-cash-bank",
      },
      {
        id: "supplierAccount",
        title: "Cuenta Provedores",
        path: "/supplier-account-cash-bank",
      },
      {
        id: "employees-accounts",
        title: "C. Trabajadores",
        path: "/employees-accounts",
      },
      {
        id: "others",
        selectedIds: [],
        title: "Categorias",
        children: [
          {
            id: "movement-category",
            title: "Categoria",
            path: "/movement-category",
          },
          {
            id: "movementSubcategory",
            title: "Sub Categoria",
            path: "/movement-subcategory",
          },
        ],
      },
    ],
  },
  {
    title: "EPPS",
    path: "/personal-safety-protective-equipment",
    icon: "DatasetIcon",
    size: "70%",
    position: "50% 30px",
    children: [
      {
        title: "EPPS",
        id: "personal-safety-protective-equipment",
        path: "/personal-safety-protective-equipment",
        single: true,
      },
    ],
  },
];
